import React from 'react';
import ReactDOM from 'react-dom';
// import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import * as serviceWorker from './serviceWorker';
import App from './App';
import common_fr from './translations/fr/common.json'; // eslint-disable-line
import common_en from './translations/en/common.json'; // eslint-disable-line

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
  },
});

// const rootElement = document.getElementById('root');
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <I18nextProvider i18n={i18next}>
//       <BrowserRouter basename={process.env.PUBLIC_URL}>
//         <App />
//       </BrowserRouter>
//     </I18nextProvider>,
//     rootElement,
//   );
// } else {
//   render(
//     <I18nextProvider i18n={i18next}>
//       <BrowserRouter basename={process.env.PUBLIC_URL}>
//         <App />
//       </BrowserRouter>
//     </I18nextProvider>,
//     rootElement,
//   );
// }

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
