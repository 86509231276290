import React from 'react';
import './style.scss';

const Collection = (props) => {
  const { children } = props; // eslint-disable-line
  return (
    <div className="collection">
      {children}
    </div>
  );
};

export default Collection;
