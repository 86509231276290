import React, { Component } from 'react';
import propTypes from 'prop-types';
import './style.scss';
import Media from 'react-media';

class Thumbnail extends Component {
  componentDidMount() {
    const tooltip = document.querySelectorAll('.project_thumbnail span');
    function fn(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = tooltip.length; i--;) {
        tooltip[i].style.left = `${e.pageX}px`;
        tooltip[i].style.top = `${e.pageY - 150}px`;
      }
    }
    document.addEventListener('mousemove', fn, false);
  }

  render() {
    const { imageSrc, title } = this.props;
    return (
      <div className="project_thumbnail">
        <Media
          query="(max-width: 768px)"
          render={() => (
            <h2>{title}</h2>
          )}
        />
        <img src={imageSrc} alt={title} />
        <Media
          query="(min-width: 769px)"
          render={() => (
            <span>{title}</span>
          )}
        />
      </div>
    );
  }
}

Thumbnail.propTypes = {
  imageSrc: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};


export default Thumbnail;
