/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import Media from 'react-media';
import { withNamespaces } from 'react-i18next';
import Meta from '../../components/Meta';
import { endpoints } from '../../api';
import './style.scss';
import Loading from '../../components/Loading';

class About extends Component {
  state = {
    api: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'About | Lou Sautreau';
    const aboutAPI = endpoints.about;
    axios.get(aboutAPI)
      .then((res) => {
        this.setState({
          api: [res.data],
        });
      });
  }

  render() {
    const { api } = this.state;
    const { t, i18n } = this.props;
    const lang = (i18n.language === 'en');
    const date = new Date().getFullYear();

    const bio = api.map(place => (
      <Fragment key="section 1">
        <div dangerouslySetInnerHTML={{ __html: lang ? place.acf.description_en : place.acf.description_fr }} />
        <h2>{t('titles.sales')}</h2>
        <ul>
          {place.acf.sales.map(i => (
            <li key={i.place}>
              <a href={i.link}><b>{i.place}</b></a>
              {` : ${i.address}, ${lang ? i.city_en : i.city_fr}.`}
            </li>
          ))}
        </ul>
      </Fragment>
    ));
    const infos = api.map(info => (
      <Fragment key="section 2">
        <h2>{t('titles.exhibitions')}</h2>
        <ul>
          {info.acf.exhibitions.map(i => (
            <li key={i.title}>
              <b>{i.title}</b>
              {`, ${i.place}, ${lang ? i.city_en : i.city_fr}, ${i.date}.`}
            </li>
          ))}
        </ul>
        <h2>{t('titles.teaching')}</h2>
        <ul>
          {info.acf.teaching.map(i => (
            <li key={i.title_en}>
              {`${i.date}, `}
              <b>{lang ? i.title_fr : i.title_en}</b>
              {`, ${i.place}, ${lang ? i.city_fr : i.city_en}.`}
            </li>
          ))}
        </ul>
        <h2>{t('titles.education')}</h2>
        <ul>
          {info.acf.education.map(i => (
            <li key={i.section_en}>
              {`${i.year}, `}
              <b>{lang ? i.section_fr : i.section_en}</b>
              {`, ${i.school}, ${lang ? i.city_fr : i.city_en}.`}
            </li>
          ))}
        </ul>
      </Fragment>
    ));

    return (
      <>
        <Meta
          title={`${t('pages.about')} | Lou Sautreau`}
          description={api.length && (
            lang
              ? api[0].acf.description_en.replace(/<(.*?)>/g, '').replace('&#8217;', '\'')
              : api[0].acf.description_fr.replace(/<(.*?)>/g, '').replace('&#8217;', '\'')
          )}
          url="about"
          img={api.length && api[0].acf.portrait.sizes.large}
        />
        {api.length ? (
          <>
            <span className="backgroundDesktopInfo" />
            <div className="about">
              <section>
                {api.map(image => (
                  <img src={image.acf.portrait.sizes.large} alt={image.acf.portrait.title} key={image.acf.portrait.id} />
                ))}
                <div className="bio">
                  {bio}
                  <Media
                    query="(min-width: 769px)"
                    render={() => (
                      <div className="credits">
                        <p><b>{t('credits.credits')}</b></p>
                        <p>
                          {t('credits.rights')}
                          {date}
                      .
                        </p>
                        <p><b>{t('credits.dev')}</b></p>
                        <p>
                          <a href="https://johangiraud.com/">Johan Giraud</a>
                      &nbsp;&amp;&nbsp;
                          <a href="https://pedroseromenho.com/">Pedro Seromenho</a>
                        </p>
                      </div>
                    )}
                  />
                </div>
              </section>
              <section>
                <div className="infos">{infos}</div>
              </section>
            </div>
          </>
        ) : <Loading />}
      </>
    );
  }
}

About.propTypes = {
  i18n: propTypes.instanceOf(Object).isRequired,
  t: propTypes.func.isRequired,
};


export default withNamespaces('common')(About);
