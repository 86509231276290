import React from 'react';
import propTypes from 'prop-types';
import Helmet from 'react-helmet';

const Meta = (props) => {
  const {
    title, description, imgHeight, imgWidth, url, img, styles// eslint-disable-line
  } = props;

  return (
    <Helmet>
      <meta name="title" content={title} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://lousautreau.com/${url}`} />
      <meta name="robots" content="index, follow" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta property="og:image:height" content={imgHeight} />
      <meta property="og:image:width" content={imgWidth} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://lousautreau.com/${url}`} />
      <meta property="og:image" content={img} />
      <style>{styles}</style>
    </Helmet>
  );
};

Meta.propTypes = {
  title: propTypes.string,
  url: propTypes.string,
};

Meta.defaultProps = {
  title: '',
  url: '',
};


export default Meta;
