import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import './style.scss';

class Unique extends Component {
  componentDidMount() {
    const containerUnique = document.querySelector('.unique__desktop');
    function horizontalScroll(event) {
      if (event.deltaY !== 0) {
        const goLeft = (containerUnique.scrollLeft + event.deltaY);
        const getMarginTop = (document.body.clientHeight - containerUnique.clientHeight);
        containerUnique.scrollLeft = goLeft;
        const getBottom = ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
        event.preventDefault();
        if (window.scrollX === 0) {
          window.scroll(0, event.deltaY + window.scrollY);
          event.preventDefault();
          if (containerUnique.scrollLeft > 0 && getBottom) {
            window.scroll(0, window.scrollY + getMarginTop);
          }
        }
      }
    }
    if (containerUnique) {
      containerUnique.addEventListener('wheel', horizontalScroll, { passive: false });
    }
  }

  render() {
    const { children } = this.props; // eslint-disable-line
    return (
      <Fragment>
        <Media
          query="(max-width: 769px)"
          render={() => (
            <div className="unique__mobile">
              {children}
            </div>
          )}
        />
        <Media
          query="(min-width: 769px)"
          render={() => (
            <div className="unique__desktop">
              {children}
            </div>
          )}
        />
      </Fragment>
    );
  }
}

export default Unique;
