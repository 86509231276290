import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Flickity from 'react-flickity-component';
import { withNamespaces } from 'react-i18next';
import { endpoints } from '../../api';
import s from './style.module.scss';
import './flickity.scss';
import Loading from '../../components/Loading';
import Media from 'react-media';
import Meta from '../../components/Meta';

const flickityOptions = {
  initialIndex: 0,
  autoPlay: 4500,
  pauseAutoPlayOnHover: false,
  imagesLoaded: true,
  lazyLoad: true,
  wrapAround: true,
  arrowShape: {
    x0: 5,
    x1: 52,
    y1: 50,
    x2: 57,
    y2: 45,
    x3: 15,
  },
};

class Home extends Component {
  state = {
    api: [],
    description: null,
  };

  componentDidMount() {
    const homeAPI = endpoints.home;
    axios.get(homeAPI)
      .then((res) => {
        this.setState({
          api: [res.data],
        });
      });

    const aboutAPI = endpoints.about;
    axios.get(aboutAPI)
      .then((res) => {
        this.setState({
          description: res.data.acf,
        });
      });

    if (document.getElementById('fixedMenu') !== null) {
      if (document.title === 'Lou Sautreau — Jewelry Designer' && document.getElementById('fixedMenu').className === 'background__box-shadow') {
        document.getElementById('fixedMenu').className = 'background__menu';
      }
    }
  }

  render() {
    const { api, description } = this.state;
    const { t, i18n } = this.props;
    const lang = (i18n.language === 'en');

    const images = api.map(image => (
      image.acf.gallery.map(i => (
        <div className={s.container} key={i.id}>
          <img data-flickity-lazyload={i.sizes.large} alt={i.alt} />
        </div>
      ))));

    const featuredImg = api.length && api[0].acf.gallery[0].sizes.medium;
    if (api.length > 0) {
      return (
        <Media query={{
          maxWidth: 680,
        }}
        >
          {matches => (
            <Fragment>
              <Meta
                title={`Lou Sautreau — ${t('titles.profession')}`}
                description={description !== null && (
                  lang
                    ? description.description_en.replace(/<(.*?)>/g, '').replace('&#8217;', '\'')
                    : description.description_fr.replace(/<(.*?)>/g, '').replace('&#8217;', '\'')
                )}
                img={featuredImg}
                styles={'nav .background__menu{background: transparent}'}
              />
              <Flickity
                options={{ ...flickityOptions, prevNextButtons: !matches }}
              >
                {images}
              </Flickity>
            </Fragment>
          )}
        </Media>
      );
    }
    return (
      <Loading />
    );
  }
}

export default withNamespaces('common')(Home);
