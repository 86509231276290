/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import Media from 'react-media';
import AOS from 'aos';
import s from './style.module.scss';
import { endpoints } from '../../api';
import Meta from '../../components/Meta';
import CoverProject from '../../components/CoverProject';
import Navigation from '../../components/Navigation';
import Unique from '../Unique';
import Collection from '../Collection';
import Loading from '../../components/Loading';


import 'aos/dist/aos.css';

class ProjectsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: [],
      location: this.props.location.pathname,
    };
  }

  componentDidMount() {
    const projectsAPI = endpoints.projects;
    axios.get(projectsAPI)
      .then((res) => {
        this.setState({
          api: [res.data],
        });
      });

    AOS.init({
      duration: 1000,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.state.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { api } = this.state;
    const { i18n, t, location } = this.props;
    const lang = (i18n.language === 'en');
    const currentId = this.props.match.params.id; // eslint-disable-line


    document.title = (`${
      currentId
        .split('-')
        .join(' ')
        .split(' ')
        .map(l => l.charAt(0).toUpperCase() + l.substring(1))
        .join(' ')
    } | Lou Sautreau`);

    const nav = api.map(item => (
      item.map((i) => { // eslint-disable-line
        const linkName = i.acf.title_en.split(' ').join('-').toLowerCase();
        const getIndex = (item.findIndex(x => x.acf.title_en.toLowerCase() === currentId.split('-').join(' ')));

        if (linkName === currentId) {
          return (
            <div key={`nav${i.id} `} className={s.container__nav}>
              <Link to="/projects" className={s.container__back}>{t('nav.back')}</Link>
              <Media query={{
                maxWidth: 680,
              }}
              >
                {matches => (
                  matches ? (
                    <div className={s.container__navMobile}>
                      <Link to={`/projects/${
                        item.length - 1 !== getIndex
                          ? item[getIndex + 1].acf.title_en.split(' ').join('-').toLowerCase()
                          : item[0].acf.title_en.split(' ').join('-').toLowerCase()
                      }`}
                      >
                    Next
                      </Link>
                      <Link to={`/projects/${
                        getIndex !== 0
                          ? item[getIndex - 1].acf.title_en.split(' ').join('-').toLowerCase()
                          : item[item.length - 1].acf.title_en.split(' ').join('-').toLowerCase()
                      }`}
                      >
                    Prev
                      </Link>
                    </div>
                  ) : (
                    <Fragment>
                      <Navigation
                        className={s.container__next}
                        link={`/projects/${
                          item.length - 1 !== getIndex
                            ? item[getIndex + 1].acf.title_en.split(' ').join('-').toLowerCase()
                            : item[0].acf.title_en.split(' ').join('-').toLowerCase()
                        }`}
                        type={t('nav.next')}
                        style={{ right: 10 }}
                        transform="translate(100, 100) rotate(180)"
                        tooltip={
                  item.length - 1 !== getIndex
                    ? item[getIndex + 1].acf.title_en
                    : item[0].acf.title_en
                }
                        onClick={() => this.setState({ location: location.pathname })}
                      />
                      <Navigation
                        className={s.container__prev}
                        link={`/projects/${
                          getIndex !== 0
                            ? item[getIndex - 1].acf.title_en.split(' ').join('-').toLowerCase()
                            : item[item.length - 1].acf.title_en.split(' ').join('-').toLowerCase()
                        }`}
                        type={t('nav.previous')}
                        style={{ left: 10 }}
                        tooltip={
                  getIndex !== 0
                    ? item[getIndex - 1].acf.title_en
                    : item[item.length - 1].acf.title_en
                }
                      />


                    </Fragment>
                  )
                )}
              </Media>
            </div>
          );
        }
      })
    ));

    const projects = api.map(project => (
      project.map((p) => { // eslint-disable-line
        const linkName = p.acf.title_en.split(' ').join('-').toLowerCase();
        if (linkName === currentId) {
          return (
            <Fragment key={p.id}>
              <CoverProject
                title={lang ? p.acf.title_en : p.acf.title_fr}
                description={lang ? p.acf.heading_en : p.acf.heading_fr}
              />
              {(p.acf.type === 'collection')
                ? (
                  <Collection>
                    {
                  p.acf.gallery.map(item => (
                    <img src={item.sizes.large} alt={item.title} key={item.id} data-aos="fade-up" />
                  ))
                }
                    <div dangerouslySetInnerHTML={{ __html: lang ? p.acf.description_en : p.acf.description_fr }} data-aos="fade-left" />
                  </Collection>
                )
                : (
                  <Unique>
                    {
                p.acf.gallery.map(item => (
                  <img src={item.sizes.large} alt={item.title} key={item.id} data-aos="fade-up" />
                ))
              }
                    <div dangerouslySetInnerHTML={{ __html: lang ? p.acf.description_en : p.acf.description_fr }} data-aos="fade-left" />
                  </Unique>
                )
          }
            </Fragment>
          );
        }
      })
    ));

    function replace(item) {
      return (
        item
          .replace(/<(.*?)>/g, '')
          .replace('&#8217;', '\'')
          .replace('&nbsp;', ' ')
      );
    }

    const seo = api.map(project => (
      project.map((p) => { // eslint-disable-line
        const linkName = p.acf.title_en.split(' ').join('-').toLowerCase();
        if (linkName === currentId) {
          return (
            <Meta
              title={`${lang ? p.acf.title_en : p.acf.title_fr} | Lou Sautreau`}
              description={lang ? replace(p.acf.description_en) : replace(p.acf.description_fr)}
              url={currentId}
              img={p.acf.gallery[0].sizes.large}
            />
          );
        }
      })
    ));
    return (
      <>
        {seo}
        {api.length
          ? (
            <div className={s.container}>
              {projects}
              {nav}
            </div>
          ) : <Loading />}
      </>
    );
  }
}

ProjectsDetails.propTypes = {
  i18n: propTypes.instanceOf(Object).isRequired,
  t: propTypes.instanceOf(Object).isRequired,
};

export default withNamespaces('common')(ProjectsDetails);
