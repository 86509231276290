import React from 'react';
import propTypes from 'prop-types';
import s from './style.module.scss';

const CoverProject = (props) => {
  const { title, description } = props;
  return (
    // <div className={s.container} style={{ backgroundImage: `url("${image}")` }}>
    <div className={s.container}>
      <h2 className={s.container__title}>
        {title}
      </h2>
      <p>{description}</p>
    </div>
  );
};

CoverProject.propTypes = {
  description: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};


export default CoverProject;
