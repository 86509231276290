import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import NavLang from '../../components/NavLang';
import { endpoints } from '../../api';
import './style.scss';

class Footer extends Component {
  state = {
    api: [],
  };

  componentDidMount() {
    const homeAPI = endpoints.home;
    axios.get(homeAPI)
      .then((res) => {
        this.setState({
          api: [res.data],
        });
      });
  }

  render() {
    const { location } = this.props;
    const { api } = this.state;
    // eslint-disable-next-line react/prop-types
    const footer = api.map(item => (
      <Fragment key="footer">
        <ul>
          {item.acf.contact.slice(0, item.acf.contact.length - 1).map(el => (
            <li key={el.title} className={location.pathname === '/about' ? 'linkDark' : null}>
              <a
                href={el.link}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: location.pathname === '/about' ? 'white' : null }}
              >
                {el.title}
              </a>
            </li>
          ))}
        </ul>
      </Fragment>
    ));

    return (
      <Fragment>
        <footer style={{ backgroundColor: location.pathname === '/about' ? 'black' : null }}>
          {footer}
          <Media
            query="(min-width: 680px)"
            render={() => (
              <NavLang />
            )}
          />
        </footer>
      </Fragment>
    );
  }
}

export default withRouter(Footer);
