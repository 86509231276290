import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import propTypes from 'prop-types';
import './style.scss';

class NavLang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'fr',
    };
  }

  render() {
    const { lang } = this.state;
    const { i18n } = this.props;
    const currentLang = (i18n.language === 'fr');
    return (
      <ul className="lang">
        <li>
          <button
            onClick={currentLang
              ? () => i18n.changeLanguage('en') && this.setState({ lang: 'fr' })
              : () => i18n.changeLanguage('fr') && this.setState({ lang: 'en' })
            }
            type="button"
            aria-label={lang}
          >
            {lang}
          </button>
        </li>
      </ul>
    );
  }
}

NavLang.propTypes = {
  i18n: propTypes.instanceOf(Object).isRequired,
};


export default withNamespaces('common')(NavLang);
