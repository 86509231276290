import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { endpoints } from '../../api';
import NavLang from '../NavLang';
import './navigation.scss';

const activeLink = {
  textDecoration: 'underline',
};

class MenuMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      selectOpen: false,
      api: [],
    };
    this.openSelect = this.openSelect.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    const projectsAPI = endpoints.projects;
    axios.get(projectsAPI)
      .then((res) => {
        this.setState({
          api: [res.data],
        });
      });

    function shadowMenu() {
      if (document.getElementById('fixedMenu') !== null && document.getElementById('fixedMenu').className !== null && document.title !== 'Lou Sautreau — Jewerlry Designer') {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          document.getElementById('fixedMenu').className = 'background__box-shadow';
        } else {
          document.getElementById('fixedMenu').className = 'background__menu';
        }
      }
    }

    window.onscroll = () => {
      shadowMenu();
    };
  }

  toggleMenu() {
    const { menu } = this.state;
    this.setState({ menu: !menu });
  }

  closeMenu() {
    this.setState({ menu: false });
  }

  openSelect() {
    const { selectOpen } = this.state;
    this.setState({
      selectOpen: !selectOpen,
    });
  }

  render() {
    const { t, i18n } = this.props;
    const { menu, api } = this.state;
    const lang = (i18n.language === 'en');

    const projects = api.map(p => (
      p.map(item => (
        <li key={item.id}>
          <NavLink to={`${process.env.PUBLIC_URL}/projects/${item.acf.title_en.split(' ').join('-').toLowerCase()}`} onClick={() => this.toggleMenu()} activeStyle={activeLink}>
            {lang ? item.acf.title_en : item.acf.title_fr}
          </NavLink>
        </li>
      ))
    ));

    return (
      <nav>
        <div className={menu ? 'open' : ''}>
          <div
            className="menu-toggler"
            onClick={() => this.toggleMenu()}
            onKeyPress={() => this.toggleMenu()}
            role="button"
            tabIndex={0}
            aria-label="Menu"
          >
            <span className="menu-toggler__line" />
            <span className="menu-toggler__line" />
            <span className="menu-toggler__line" />
          </div>
          <div className="background__menu" id="fixedMenu" />
          <div className="title">
            <Link
              to="/"
              onClick={this.closeMenu}
              onKeyPress={this.closeMenu}
              role="button"
              tabIndex={0}
              aria-label={t('pages.louSautreau')}
            >
              {t('pages.louSautreau')}
            </Link>
            <span>{t('titles.profession')}</span>
          </div>
          <div className="side-bar">
            <ul style={{ marginTop: 100, fontSize: 18 }}>
              <li><NavLink to="/projects" activeStyle={activeLink} onClick={() => this.toggleMenu()}>{t('pages.projects')}</NavLink></li>
              <li><NavLink to="/about" activeStyle={activeLink} onClick={() => this.toggleMenu()}>{t('pages.about')}</NavLink></li>
            </ul>
            <ol style={{ marginTop: 100 }}>{projects}</ol>
            <NavLang />
          </div>
        </div>
      </nav>
    );
  }
}
MenuMobile.propTypes = {
  t: propTypes.func.isRequired,
  i18n: propTypes.instanceOf(Object).isRequired,
};

export default withNamespaces('common')(MenuMobile);
