import React, { Component, Fragment } from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import Media from 'react-media';
import { endpoints } from '../../api';
import Thumbnail from '../../components/Thumbnail';
import Meta from '../../components/Meta';
import s from './style.module.scss';
import Loading from '../../components/Loading';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: [],
      projectType: '',
      underlineAll: 'underline',
      underlineCollections: 'none',
      underlineUnique: 'none',
      description: null,
    };
    this.getCollections = this.getCollections.bind(this);
    this.getUnique = this.getUnique.bind(this);
    this.getAll = this.getAll.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const projectsAPI = endpoints.projects;
    axios.get(projectsAPI)
      .then((res) => {
        this.setState({
          api: [res.data],
        });
      });

    const aboutAPI = endpoints.about;
    axios.get(aboutAPI)
      .then((res) => {
        this.setState({
          description: res.data.acf,
        });
      });
  }

  getCollections() {
    window.scrollTo(0, 0);
    this.setState({
      projectType: 'collection',
      underlineAll: 'none',
      underlineCollections: 'underline',
      underlineUnique: 'none',
    });
  }

  getUnique() {
    window.scrollTo(0, 0);
    this.setState({
      projectType: 'unique',
      underlineAll: 'none',
      underlineCollections: 'none',
      underlineUnique: 'underline',
    });
  }

  getAll() {
    window.scrollTo(0, 0);
    this.setState({
      projectType: '',
      underlineAll: 'underline',
      underlineCollections: 'none',
      underlineUnique: 'none',
    });
  }


  render() {
    const {
      api, projectType, underlineAll, underlineCollections, underlineUnique, description,
    } = this.state;
    const { t, i18n } = this.props;
    const lang = (i18n.language === 'en');
    const projects = api.map(project => (
      project.map((p) => { // eslint-disable-line
        if (p.acf.type === projectType || projectType === '') {
          return (
            <Link to={`${process.env.PUBLIC_URL}/projects/${p.acf.title_en.split(' ').join('-').toLowerCase()}`} key={p.acf.cover_image.id}>
              <Thumbnail
                imageSrc={p.acf.cover_image.sizes.large}
                title={lang ? p.acf.title_en : p.acf.title_fr}
              />
            </Link>
          );
        }
      })
    ));

    const featuredImg = api.length && api[0][0].acf.gallery[0].sizes.medium;

    if (api.length > 0) {
      return (
        <Fragment>
          <Meta
            title={`${t('pages.projects')} | Lou Sautreau`}
            description={description !== null && (
              lang
                ? description.description_en.replace(/<(.*?)>/g, '').replace('&#8217;', '\'')
                : description.description_fr.replace(/<(.*?)>/g, '').replace('&#8217;', '\'')
            )}
            url="projects"
            img={featuredImg}
          />
          <Media
            query="(min-width: 769px)"
            render={() => (
              <ul className={s.menu}>
                <li><button onClick={this.getAll} onKeyPress={this.getAll} type="button" tabIndex={0} style={{ textDecoration: underlineAll }}>{t('sort.all')}</button></li>
                <li><button onClick={this.getCollections} onKeyPress={this.getCollections} type="button" tabIndex={0} style={{ textDecoration: underlineCollections }}>{t('sort.collections')}</button></li>
                <li><button onClick={this.getUnique} onKeyPress={this.getUnique} type="button" tabIndex={0} style={{ textDecoration: underlineUnique }}>{t('sort.uniquePieces')}</button></li>
              </ul>
            )}
          />
          <div className={s.container}>
            {projects}
          </div>
        </Fragment>
      );
    } return (
      <Loading />
    );
  }
}

Projects.propTypes = {
  i18n: propTypes.instanceOf(Object).isRequired,
  t: propTypes.func.isRequired,
};

export default withNamespaces('common')(Projects);
