import React from 'react';
import Lottie from 'react-lottie';
import screenSplash from '../../lottie/loading.json';
import s from './style.module.scss';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: screenSplash,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loading = () => (
  <div className={s.container}>
    <Lottie
      options={defaultOptions}
      height={300}
      width={300}
    />
  </div>
);

export default Loading;
