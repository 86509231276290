import React, { Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './style.scss';
import propTypes from 'prop-types';

const Navbar = (props) => {
  const { t } = props;
  return (
    <Fragment>
      <h1 className="siteTitle">
        <NavLink to="/">
          {t('pages.louSautreau')}
        </NavLink>

      </h1>
      <ul>
        <li><NavLink to="/projects">{t('pages.projects')}</NavLink></li>
        <li><NavLink to="/about">{t('pages.about')}</NavLink></li>
      </ul>
    </Fragment>
  );
};

Navbar.propTypes = {
  t: propTypes.func.isRequired,
};

export default withNamespaces('common')(Navbar);
