import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Navigation = (props) => {
  const {
    className, link, transform, type, tooltip, style, onClick//eslint-disable-line
  } = props;
  return (
    <div className={className} onClick={onClick}>
      <Link to={link}>
        <button className="flickity-button flickity-prev-next-button next" type="button" aria-label={type} style={style}>
          <svg className="flickity-button-icon" viewBox="0 0 100 100">
            <path d="M 5,50 L 52,100 L 57,95 L 15,50  L 57,5 L 52,0 Z" className="arrow" transform={transform} />
          </svg>
        </button>
      </Link>
      <span>{tooltip}</span>
    </div>
  );
};

Navigation.propTypes = {
  className: propTypes.string,
  link: propTypes.string,
  transform: propTypes.string,
  type: propTypes.string,
  tooltip: propTypes.string,
};

Navigation.defaultProps = {
  className: '',
  link: '',
  transform: '',
  type: '',
  tooltip: '',
};


export default Navigation;
