import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import About from './containers/About';
import Footer from './containers/Footer';
import Header from './containers/Header';
import Home from './containers/Home';
import Projects from './containers/Projects';
import ProjectDetails from './containers/ProjectDetails';
import NoMatch from './containers/NoMatch';
import './App.css';
import './App.module.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <Route render={({ location }) => (
        <Switch location={location}>
          <Route exact path="/" component={Home} />
          <Route exact path="/projects" component={Projects} />
          <Route path="/projects/:id" component={ProjectDetails} />
          <Route path="/about" component={About} />
          <Route path="" component={NoMatch} />
        </Switch>
      )}
      />
      <Footer />
    </div>
  );
}

export default withRouter(App);
