import React from 'react';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import s from './style.module.scss';

const NoMatch = (props) => {
  // eslint-disable-next-line react/prop-types
  const { t } = props;
  return (
    <div className={s.container}>
      <img src="/assets/images/gear.svg" alt="gear turning" />
      <p>{t('pages.noMatch')}</p>
      <NavLink to="/" className={s.scontainer__link}>{t('pages.noMatchLink')}</NavLink>
    </div>
  );
};


export default withNamespaces('common')(NoMatch);
