import React, { Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import Media from 'react-media';
import Navbar from '../../components/Navbar';
import MenuMobile from '../../components/MenuMobile';
import './style.scss';

const Header = (props) => {
  const { t } = props; //eslint-disable-line
  return (
    <header>
      <Media
        query="(max-width: 680px)"
        render={() => (
          <MenuMobile />
        )}
      />
      <Media
        query="(min-width: 680px)"
        render={() => (
          <Fragment>
            <Navbar />
            <h3>{t('titles.profession')}</h3>
          </Fragment>
        )}
      />
    </header>
  );
};

export default withNamespaces('common')(Header);
